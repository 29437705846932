import { Breadcrumbs, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import palette from '../../common/theme/palette';

const NavigationBreadCrumbs = ({ urls = [], labels = [] }) => (
  <Toolbar>
    <Breadcrumbs aria-label="breadcrumb">
      {urls.map((path, index) => (
        <Link key={path} to={path} style={{ textDecoration: 'none' }}>
          <Typography variant="h5" sx={{ color: (index + 1 === labels.length) ? palette.primary.main : palette.secondary.main }}>
            {labels[index]}
          </Typography>
        </Link>
      ))}
    </Breadcrumbs>
  </Toolbar>
);

export default NavigationBreadCrumbs;
