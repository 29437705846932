import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import GppGoodIcon from '@mui/icons-material/GppGood';
import BuildIcon from '@mui/icons-material/Build';
import { Tooltip } from '@mui/material';
import { formatMsecToHours } from './formatter';
import { useTranslation } from '../components/LocalizationProvider';

export const getProduction = (item) => {
  switch (item.productionUnit) {
    case 'pumpedMaterial':
      return item.productionValue;
    case 'engineHours':
      return formatMsecToHours(item.productionValue);
    default:
      return item.productionValue;
  }
};

export const AchievementStatusIcon = (item) => {
  const production = getProduction(item);
  const { goal } = item;
  if (production >= goal * 0.9) {
    return <CheckBoxIcon color="success" />;
  }
  if (production >= goal * 0.75) {
    return <WarningIcon color="warning" />;
  }
  return <ErrorIcon color="error" />;
};

export const MaintenanceStatusIcon = ({ maintenance }) => {
  const t = useTranslation();
  switch (maintenance) {
    case true:
      return (
        <Tooltip title={`${t('attributeMaintenance')}`}>
          <BuildIcon color="error" />
        </Tooltip>
      );
    case false:
      return <GppGoodIcon color="success" />;
    default:
      return <GppGoodIcon color="success" />;
  }
};
