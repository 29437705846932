/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { DataGrid, GridToolbar, GridFooter } from '@mui/x-data-grid';

const CustomDataGrid = ({
  columns = [],
  rows = [],
  checkboxSelection = false,
  selections,
  setSelections = () => { },
  disableColumnFilter = true,
  rowHeight = 24,
  getRowId = (row) => row.id || row.deviceid || row.deviceId,
}) => {
  useEffect(() => {
    console.log('Columns update');
  }, [columns]);

  useEffect(() => {
    console.log('Rows update');
    console.log(rows);
  }, [rows]);

  useEffect(() => {
    console.log('Checkbox selection update');
  }, [checkboxSelection]);

  useEffect(() => {
    console.log('Selection model update', selections);
  }, [selections]);

  return (
    <DataGrid
      ignoreDiacritics
      columns={columns}
      rows={rows}
      /* hideFooter */
      /* hideFooterPagination */
      hideFooterSelectedRowCount
      pagination
      pageSizeOptions={[{ value: -1, label: 'All' }]}
      getRowId={getRowId}
      checkboxSelection={checkboxSelection}
      rowBufferPx={100}
      columnBufferPx={500}
      rowHeight={rowHeight}
      disableColumnFilter={disableColumnFilter}
      disableColumnSelector
      disableDensitySelector
      keepNonExistentRowsSelected
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setSelections(newRowSelectionModel);
      }}
      rowSelectionModel={selections}
    />
  );
};

export default CustomDataGrid;
