import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody,
  Avatar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));

const OperationsPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/express/operation/categories');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const formatValue = (item, key) => {
    switch (key) {
      case 'name':
        return t(`category${item.name.replace(/^\w/, (c) => c.toUpperCase())}`);
      case 'productionUnit':
        return t(`report${item.productionUnit.replace(/^\w/, (c) => c.toUpperCase())}`);
      case 'productionGoal':
        switch (item.productionUnit) {
          case 'engineHours':
            return `${item.productionGoal} ${t('sharedHours')}`;
          case 'pumpedMaterial':
            return `${item.productionGoal} ${t('sharedCubicMeterAbbreviation')}`;
          default:
            return item[key];
        }
      case 'productionTimePeriod':
        if (item.productionTimePeriod === 1) {
          return `${item.productionTimePeriod} ${t('sharedDay')}`;
        }
        return `${item.productionTimePeriod} ${t('sharedDays')}`;
      default:
        return item[key];
    }
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedDrivers']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t('deviceCategory')}</TableCell>
            <TableCell>{t('attributeProductionUnit')}</TableCell>
            <TableCell>{t('attributeProductionGoal')}</TableCell>
            <TableCell>{t('attributeProductionTimePeriod')}</TableCell>
            <TableCell className={classes.columnAction} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
            <TableRow key={item.id}>
              <TableCell sx={{ py: 0 }}>
                <Avatar src={mapIcons[mapIconKey(item.name)]} variant="rounded" sx={{ height: '100%' }} />
              </TableCell>
              <TableCell>{formatValue(item, 'name')}</TableCell>
              <TableCell>{formatValue(item, 'productionUnit')}</TableCell>
              <TableCell>{formatValue(item, 'productionGoal')}</TableCell>
              <TableCell>{formatValue(item, 'productionTimePeriod')}</TableCell>
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions itemId={item.id} editPath="/settings/operation" endpoint="operation" setTimestamp={setTimestamp} noRemove />
              </TableCell>
            </TableRow>
          )) : (<TableShimmer columns={6} endAction />)}
        </TableBody>
      </Table>
    </PageLayout>
  );
};

export default OperationsPage;
