export default [
  { label: 'default', type: 'default' },
  { label: 'animal', type: 'default' },
  { label: 'bicycle', type: 'default' },
  { label: 'boat', type: 'default' },
  { label: 'bus', type: 'default' },
  { label: 'car', type: 'default' },
  { label: 'crane', type: 'default' },
  { label: 'helicopter', type: 'default' },
  { label: 'motorcycle', type: 'default' },
  { label: 'offroad', type: 'default' },
  { label: 'person', type: 'default' },
  { label: 'pickup', type: 'default' },
  { label: 'plane', type: 'default' },
  { label: 'ship', type: 'default' },
  { label: 'tractor', type: 'heavy-duty' },
  { label: 'train', type: 'default' },
  { label: 'tram', type: 'default' },
  { label: 'trolleybus', type: 'default' },
  { label: 'truck', type: 'default' },
  { label: 'van', type: 'default' },
  { label: 'scooter', type: 'default' },
  { label: 'miniloader', type: 'heavy-duty' },
  { label: 'excavator', type: 'heavy-duty' },
  { label: 'backhoeLoader', type: 'heavy-duty' },
  { label: 'pileDriver', type: 'heavy-duty' },
  { label: 'auxCranePileDriver', type: 'heavy-duty' },
  { label: 'stationaryConcretePump', type: 'heavy-duty' },
  { label: 'dumpTruck', type: 'heavy-duty' },
  { label: 'vibratorySoilCompactor', type: 'heavy-duty' },
  { label: 'courier', type: 'default' },
];
