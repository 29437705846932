import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import palette from '../../common/theme/palette';

const TotalCard = ({ title, value, width }) => (
  <Grid item xs={width}>
    <Box sx={{ py: 1.5, borderRadius: 4, bgcolor: palette.primary.main, height: 100, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" color={palette.primary.contrastText} fontSize={16} textAlign="center">
        {title}
      </Typography>
      <Typography variant="h4" color={palette.primary.contrastText} fontSize={26} sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {value}
      </Typography>
    </Box>
  </Grid>
);

export default TotalCard;
