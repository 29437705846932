/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditItemView from '../settings/components/EditItemView';
import EditAttributesAccordion from '../settings/components/EditAttributesAccordion';
import { useTranslation } from '../common/components/LocalizationProvider';
import LinkField from '../common/components/LinkField';
import ReportsMenu from './components/ReportsMenu';
import { useEffectAsync } from '../reactHelper';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const MaintenanceRecordPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const { deviceId, id } = useParams();
  const devices = useSelector((state) => state.devices.items);
  const device = devices[deviceId || id] ? devices[deviceId || id] : {};
  const base = id ? null : { deviceId, maintenanceId: id, attributes: {} };
  const [item, setItem] = useState(base);
  const [computedMaintenances, setComputedMaintenances] = useState([]);

  const validate = () => item && item.deviceId && item.maintenanceId && item.date && item.lecture && item.cost;

  useEffectAsync(async () => {
    if (deviceId) {
      const response = await fetch(`/api/express/maintenances/devices/${deviceId}`);
      if (response.ok) {
        const items = await response.json();
        setComputedMaintenances(items.categoryMaintenances);
      } else {
        throw Error(await response.text());
      }
    }
  }, [item]);

  return (
    <EditItemView
      endpoint="express/maintenances/records"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<ReportsMenu />}
      breadcrumbs={['settingsTitle', 'sharedMaintenance']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                label={t('sharedDevice')}
                value={device.name || ''}
                disabled
              />
              <FormControl>
                <InputLabel>{t('sharedMaintenance')}</InputLabel>
                <Select
                  label={t('sharedMaintenance')}
                  value={item.maintenanceId || ''}
                  onChange={(e) => setItem({ ...item, maintenanceId: e.target.value })}
                  disabled={Boolean(!computedMaintenances.length || item?.id)}
                >
                  {
                    item.computedMaintenance ?
                      <MenuItem key={item.computedMaintenance.id} value={item.computedMaintenance.id}>{item.computedMaintenance.name}</MenuItem>
                      :
                      computedMaintenances.map((maintenance) => (
                        <MenuItem key={maintenance.id} value={maintenance.id}>{maintenance.name}</MenuItem>
                      ))
                  }
                </Select>
              </FormControl>
              <TextField
                label={t('sharedDate')}
                type="date"
                value={item.date ? moment(item.date).locale('en').format(moment.HTML5_FMT.DATE) : ''}
                onChange={(e) => setItem({ ...item, date: moment(e.target.value, moment.HTML5_FMT.DATE).locale('en').format() })}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label={t('sharedLecture')}
                type="number"
                value={item.lecture || ''}
                onChange={(e) => setItem({ ...item, lecture: e.target.value })}
                inputProps={{ min: 0 }}
              />
              <TextField
                label={t('sharedCost')}
                type="number"
                value={item.cost || ''}
                onChange={(e) => setItem({ ...item, cost: e.target.value })}
                InputProps={{ startAdornment: '$' }}
              />
            </AccordionDetails>
          </Accordion>
          <EditAttributesAccordion
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{}}
          />
        </>
      )}
    </EditItemView>
  );
};

export default MaintenanceRecordPage;
