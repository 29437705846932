/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import {
  TableRow, TableCell, Grid,
  Box,
  Typography,
  Toolbar,
  Divider,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  axisClasses, BarChart, ChartsAxisHighlight, ChartsXAxis, ChartsYAxis,
  pieArcLabelClasses,
  PieChart,
} from '@mui/x-charts';
import {
  formatDistance, formatHours, formatMsecToHours, formatNumericHours, formatRoundedNumericHours, formatSpeed, formatTime, formatVolume,
  formatVolumeNumeric,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import { useCatch } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import { prefixString } from '../common/util/stringUtils';
import VirtualizedTable from './components/VirtualizedTable';
import VirtualizedTableShimmer from '../common/components/VirtualizedTableShimmer';
import CorporateReportFilter from './components/CorporateReportFilter';
import palette from '../common/theme/palette';
import CustomChart from './components/CustomChart';
import { volumeUnitString } from '../common/util/converter';
import CustomDataGrid from './components/CustomDataGrid';
import { getProduction, MaintenanceStatusIcon } from '../common/util/corporateReportUtils';
import TotalCard from './components/TotalCard';
import NavigationBreadCrumbs from './components/NavigationBreadCrumbs';

const engineHourColumnsGenerator = (t, p) => ([
  {
    field: 'productionStatus',
    headerName: t('deviceStatus'),
    type: 'string',
    valueGetter: (_value, item) => getProduction(item) / item.goal,
    renderCell: (item) => <MaintenanceStatusIcon maintenance={false} />,
    width: 100,
  },
  {
    field: 'device',
    headerName: t('sharedDevice'),
    type: 'string',
    valueGetter: (_value, row) => row.name.toUpperCase(),
    width: 200,
  },
  {
    field: 'engineHours',
    headerName: t('reportEngineHours'),
    type: 'number',
    valueGetter: (_value, row) => row.hours,
    valueFormatter: (v) => formatRoundedNumericHours(v, t),
    width: 120,
  },
  {
    field: 'spentFuel',
    headerName: t('reportSpentFuel'),
    type: 'number',
    valueGetter: (_value, row) => row.spentFuel,
    valueFormatter: (v) => formatVolume(v, p.volumeUnit, t, 0),
    width: 100,
  },
  {
    field: 'distance',
    headerName: t('sharedDistance'),
    type: 'number',
    valueGetter: (_value, row) => row.totalDistance,
    valueFormatter: (v) => formatDistance(v, p.distanceUnit, t),
    width: 100,
  },
]);
const pumpedMaterialColumnsGenerator = (t, p) => ([
  {
    field: 'productionStatus',
    headerName: t('deviceStatus'),
    type: 'string',
    valueGetter: (_value, item) => getProduction(item) / item.goal,
    renderCell: (item) => <MaintenanceStatusIcon maintenance={false} />,
    width: 100,
  },
  {
    field: 'device',
    headerName: t('sharedDevice'),
    type: 'string',
    valueGetter: (_value, row) => row.name.toUpperCase(),
    width: 200,
  },
  {
    field: 'engineHours',
    headerName: t('reportEngineHours'),
    type: 'number',
    valueGetter: (_value, row) => row.hours,
    valueFormatter: (v) => formatNumericHours(v, t),
    width: 120,
  },
  {
    field: 'spentFuel',
    headerName: t('reportSpentFuel'),
    type: 'number',
    valueGetter: (_value, row) => row.spentFuel,
    valueFormatter: (v) => formatVolume(v, p.volumeUnit, t, 0),
    width: 100,
  },
  {
    field: 'pumpedMaterial',
    headerName: t('reportPumpedMaterial'),
    type: 'number',
    valueGetter: (_value, row) => row.production_value,
    valueFormatter: (v) => `${Math.round(v) || 0} ${t('sharedCubicMeterAbbreviation')}`,
    width: 100,
  },
]);

const top5ColumnsArray = [
  ['branch', 'reportBranch'],
  ['construction', 'reportConstruction'],
  ['project', 'reportProject'],
];

const columnsMap = new Map([...top5ColumnsArray]);

const CorporateStatusPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const groups = useSelector((state) => state.groups.items);
  const categories = useSelector((state) => state.categories.items);

  const deviceList = useMemo(() => Object.values(devices), [devices]);

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const splitByProperty = (array, property) => Object.values(
    array.reduce((acc, item) => {
      const key = item[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {}),
  );

  const categoryDistributionDataSeries = useMemo(() => ({
    series: [
      {
        type: 'pie',
        yAxisId: 'category',
        label: t('sharedCategory'),
        data: Object.keys(categories).map((category) => (
          {
            value: deviceList.filter((item) => item.category === category).length,
            label: t(`category${category.replace(/^\w/, (c) => c.toUpperCase())}`),
          })),
        arcLabel: (item) => item.value,
        arcLabelMinAngle: 10,
        innerRadius: 0,
        outerRadius: '150px',

      },
    ],
    sx: {
      [`& .${pieArcLabelClasses.root}`]: {
        fontWeight: 'bold',
      },
    },
    colors: palette.pieColors,
    slotProps: {
      legend: {
        direction: 'row',
        position: { vertical: 'top', horizontal: 'middle' },
        padding: 0,
      },
    },
  }), [categories, t]);

  const categoryStatus = useMemo(() => {
    const categoryList = Object.keys(categories);
    const formattedDevices = deviceList.map((device) => ({
      ...device,
      construction: groups[device.groupId]?.name,
      project: groups[groups[device.groupId]?.groupId]?.name,
      branch: groups[groups[groups[device.groupId]?.groupId]?.groupId]?.name,
    }));
    const statusData = [];

    categoryList.forEach((category) => {
      const categoryDevices = formattedDevices.filter((device) => device.category === category);
      const branchedDevices = splitByProperty(categoryDevices, 'branch');
      const categoryStatus = {
        category,
        operative: 0,
        maintenance: 0,
      };
      branchedDevices.forEach((branch) => {
        const operativeDevices = branch.filter((device) => !device.attributes?.maintenance).length;
        const maintenanceDevices = branch.filter((device) => device.attributes?.maintenance).length;
        categoryStatus.operative += operativeDevices;
        categoryStatus.maintenance += maintenanceDevices;
      });
      statusData.push(categoryStatus);
    });
    return statusData;
  }, [deviceList, groups, categories]);

  useEffect(() => {
  }, [categoryStatus]);

  const branches = [...new Set(categoryStatus.map((item) => item.branch))];

  const chartData = useMemo(() => ({
    dataset: categoryStatus,
    series: [
      {
        type: 'bar',
        yAxisId: 'engineHours',
        label: t('reportMaintenance'),
        color: palette.secondary.main,
        dataKey: 'maintenance',
        stack: 'BOGOTÁ',
        barLabel: 'BOGOTÁ',
      },
      {
        type: 'bar',
        yAxisId: 'engineHours',
        label: t('reportOperative'),
        color: palette.primary.main,
        dataKey: 'operative',
        stack: 'BOGOTÁ',
        barLabel: 'BOGOTÁ',
      },
    ],
    xAxis: [
      {
        id: 'device',
        data: Object.values(categories).map((category) => category.name),
        scaleType: 'band',
        tickLabelInterval: () => true,
        valueFormatter: (v) => t(`category${v.replace(/^\w/, (c) => c.toUpperCase())}`).replaceAll(' ', '\n'),
      },
    ],
    yAxis: [
      {
        id: 'engineHours',
        scaleType: 'linear',
      },
    ],
    chartsXAxis: [
      <ChartsXAxis
        key="engineHoursDevice"
        position="bottom"
        axisId="device"
        tickInterval={() => true}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateY(22px)',
          },
        }}
      />,
    ],
    chartsYAxis: [
      <ChartsYAxis
        key="engineHours"
        label={t('sharedHours').charAt(0).toUpperCase() + t('sharedHours').slice(1)}
        position="left"
        axisId="engineHours"
        tickLabelStyle={{ fontSize: 10 }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateX(-5px)',
          },
        }}
      />,
    ],
    chartAxisHighLight: <ChartsAxisHighlight x="band" y="none" />,
  }), [categoryStatus]);

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle']}>
      <div className={classes.container}>
        <div className={classes.header}>
          <NavigationBreadCrumbs
            urls={['/reports/maintenances']}
            labels={[t('reportAssetsStatus')]}
          />
        </div>
        <Divider />
        <div className={classes.containerMain}>
          <Grid container sx={{ flex: 1, px: 2 }} spacing={2}>
            {/* LEFT COLUMN */}
            <Grid item container xs={12} md={5} direction="column" spacing={2} sx={{ height: 640 }}>
              {/* TABLA */}
              <Grid item sx={{ width: '100%' }} xs>
                <Box sx={{ height: 492 }}>
                  <Typography variant="h4" sx={{ mb: 1 }}>{t('reportDistribution')}</Typography>
                  <PieChart
                    sx={{ bgcolor: 'blue' }}
                    margin={{ top: 0, bottom: -100, left: 0, right: 0 }}
                    {...categoryDistributionDataSeries}
                  />
                </Box>
              </Grid>
              {/* TOTALES */}
              <Grid item container columnSpacing={2} direction="row" xs="auto">
                <TotalCard
                  title={`${t('reportTotal')} ${t('deviceTitle')}`}
                  value={`${deviceList.filter((item) => item.attributes.deviceType === 'heavy-duty').length}`}
                  width={4}
                />
                <TotalCard
                  title={`${t('reportOperative')}`}
                  value={`${deviceList.filter((item) => (item.attributes.deviceType === 'heavy-duty' && !item.attributes.maintenance)).length}`}
                  width={4}
                />
                <TotalCard
                  title={`${t('reportMaintenance')}`}
                  value={`${deviceList.filter((item) => (item.attributes.deviceType === 'heavy-duty' && item.attributes.maintenance)).length}`}
                  width={4}
                />
              </Grid>
            </Grid>
            {/* RIGHT COLUMN */}
            <Grid item container xs={12} md={7} direction="column" spacing={2} sx={{ height: 640 }}>
              <Grid item container xs direction="column">
                <Grid item>
                  <Typography variant="h5">{t('reportDevicesStatus')}</Typography>
                </Grid>
                <Grid item xs height={260}>
                  <CustomChart {...chartData} margin={{ top: 40, right: 20, bottom: 60, left: 50 }} />
                </Grid>
              </Grid>
            </Grid>
            {/* TOP 5 */}

          </Grid>
        </div>
      </div>
    </PageLayout>
  );
};

export default CorporateStatusPage;
