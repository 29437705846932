/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import {
  TableRow, TableCell, Grid,
  Box,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  axisClasses, ChartsAxisHighlight, ChartsXAxis, ChartsYAxis,
} from '@mui/x-charts';
import {
  formatDistance, formatHours, formatHoursToMsec, formatMsecToHours, formatNumericHours, formatRoundedNumericHours, formatSpeed, formatTime, formatVolume,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import { useCatch } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import { prefixString } from '../common/util/stringUtils';
import VirtualizedTable from './components/VirtualizedTable';
import VirtualizedTableShimmer from '../common/components/VirtualizedTableShimmer';
import CorporateReportFilter from './components/CorporateReportFilter';
import palette from '../common/theme/palette';
import CustomChart from './components/CustomChart';
import CustomDataGrid from './components/CustomDataGrid';
import TotalCard from './components/TotalCard';

const performanceColumnsGenerator = (t, p) => ([

  {
    field: 'device',
    headerName: t('sharedDevice'),
    type: 'string',
    valueGetter: (_value, row) => row.name.toUpperCase(),
    width: 200,
  },
  {
    field: 'operativeEfficiency',
    headerName: t('reportOperativeEfficiency'),
    type: 'number',
    valueGetter: (_value, row) => row.operativeEfficiency,
    valueFormatter: (v) => `${v} %`,
    width: 200,
  },
  {
    field: 'energeticEfficiency',
    headerName: t('reportEnergeticEfficiency'),
    type: 'number',
    valueGetter: (_value, row) => row.energeticEfficiency,
    valueFormatter: (v) => v,
    width: 200,
  },
  {
    field: 'kpi',
    headerName: t('reportKpi'),
    type: 'number',
    valueGetter: (_value, row) => row.kpi,
    valueFormatter: (v) => v,
    width: 100,
  },
]);

const top5Columns = ['branch', 'construction', 'project'];
const top5ColumnsArray = [
  ['branch', 'reportBranch'],
  ['construction', 'reportConstruction'],
  ['project', 'reportProject'],
];

const columnsMap = new Map([...top5ColumnsArray]);

const CorporatePerformancePage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const groups = useSelector((state) => state.groups.items);
  const categories = useSelector((state) => state.categories.items);

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const engineHoursColumns = useMemo(() => performanceColumnsGenerator(t, { volumeUnit }), [t, volumeUnit]);

  const [items, setItems] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({ from: '', to: '' });

  const [selectedItems, setSelectedItems] = useState([]);
  const showItems = useMemo(() => items.filter((item) => (category !== '' ? item.category === category : true)), [items, category]);
  const chartItems = useMemo(() => showItems.filter((item) => selectedItems.includes(item.deviceid)), [showItems, selectedItems]);

  const filterItems = (list, key, value) => [...list].filter((item) => item[key] === value);
  const sortedItems = (list, key) => [...list].sort((a, b) => (parseInt(b[key], 10) || 0) - (parseInt(a[key], 10) || 0));
  const truncateItems = (list, count) => [...list].slice(0, count);

  const splitByProperty = (array, property, productionUnit) => Object.values(
    array.filter((item) => item.productionUnit === productionUnit).reduce((acc, item) => {
      const key = item[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {}),
  );

  const kpiDataSeries = useMemo(() => ({
    series: [
      {
        type: 'bar',
        yAxisId: 'kpi',
        label: t('reportKpi'),
        color: palette.primary.main,
        data: sortedItems(chartItems, 'kpi').map((item) => item.kpi || 0),
      },
    ],
    xAxis: [
      {
        id: 'device',
        data: sortedItems(chartItems, 'kpi').map((item) => item.name),
        scaleType: 'band',
        valueFormatter: (v, c) => {
          const name = v?.toUpperCase();
          if (name && c.location === 'tick') {
            return name.length > 10 ? `${name.slice(0, 10)}...` : name;
          }
          return name;
        },
        tickLabelInterval: () => true,
      },
    ],
    yAxis: [
      {
        id: 'kpi',
        scaleType: 'linear',
      },
    ],
    chartsXAxis: [
      <ChartsXAxis
        key="kpiDevice"
        position="bottom"
        axisId="device"
        tickInterval={() => true}
        tickLabelStyle={{
          fontSize: '8px',
          textAnchor: 'end',
          angle: -45,
        }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateY(22px)',
          },
        }}
      />,
    ],
    chartsYAxis: [
      <ChartsYAxis
        key="kpi"
        label={t('reportKpi')}
        position="left"
        axisId="kpi"
        tickLabelStyle={{ fontSize: 10 }}
        sx={{
          [`& .${axisClasses.label}`]: {
            transform: 'translateX(-5px)',
          },
        }}
      />,
    ],
    chartAxisHighLight: <ChartsAxisHighlight x="line" y="none" />,

  }), [chartItems]);

  const totalOperativeEfficiency = () => {
    if (chartItems.length < 1) {
      return 0;
    }
    return ((chartItems.reduce((acc, item) => acc + item.operativeEfficiency, 0) / chartItems.length)).toFixed(1);
  };

  const totalEnergeticEfficiency = (productionUnit) => {
    const devices = chartItems.filter((item) => (item.productionUnit === productionUnit && item.energeticEfficiency));
    if (devices.length < 1) {
      return 0;
    }
    return ((devices.reduce((acc, item) => acc + (item.energeticEfficiency), 0) / devices.length)).toFixed(1);
  };

  const handleSubmit = useCatch(async ({ from, to, branchIds, projectIds, constructionIds }) => {
    setLoading(true);
    let groupIds = [];
    if (constructionIds.length > 0) {
      groupIds = constructionIds;
    } else if (projectIds.length > 0) {
      groupIds = projectIds;
    } else if (branchIds.length > 0) {
      groupIds = branchIds;
    }
    try {
      const query = new URLSearchParams({ from, to, tablename: 'heavy_duty' });
      groupIds.forEach((groupId) => query.append('groupId', groupId));
      const response = await fetch(`/api/express/analytics/kpis?${query}`);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setItems(data);
        setDateRange({ from, to });
        setSelectedItems(data.map((item) => item.deviceid));
      } else {
        const data = await response.text();
        throw Error(data);
      }
    } finally {
      setLoading(false);
    }
  });

  const handleCategoryFilter = (category) => {
    setCategory(category);
  };

  const renderCharts = (category, t) => {
    const chartData = { title: t('reportKpi'), series: kpiDataSeries };
    /* const averageData = { title: t('reportKpi'), series: engineHoursDataSeriesAverage }; */

    return (
      <>
        {/* Main Chart */}
        <Grid item container xs direction="column">
          <Grid item>
            <Typography variant="h5">{chartData.title}</Typography>
          </Grid>
          <Grid item xs height={260}>
            <CustomChart {...chartData.series} margin={{ top: 80, right: 20, bottom: 60, left: 55 }} />
          </Grid>
        </Grid>

        {/* Average Chart */}
        {/* <Grid item container xs direction="column">
          <Grid item>
            <Typography variant="h5">{averageData.title}</Typography>
          </Grid>
          <Grid item xs height={260}>
            <CustomChart {...averageData.series} margin={{ top: 80, right: 20, bottom: 20, left: 55 }} />
          </Grid>
        </Grid> */}
      </>
    );
  };

  const formatValue = (item, key) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatTime(item[key], 'minutes', hours12);
      case 'duration':
        return formatHours(item[key]);
      case 'engineHours':
      case 'hours':
        return formatNumericHours(item[key], t);
      case 'spentFuel':
      case 'fuel_consumption':
        return formatVolume(item[key] || 0, volumeUnit, t, 0);
      case 'pumpedMaterial':
        return `${Math.round(item.productionValue) || 0} ${t('sharedCubicMeterAbbreviation')}`;
      case 'distance':
        return formatDistance(item[key], distanceUnit, t);
      case 'type':
        return t(prefixString('event', item[key]));
      case 'attributes':
        switch (item.type) {
          case 'alarm':
            return t(prefixString('alarm', item.attributes.alarm));
          case 'deviceOverspeed':
            return formatSpeed(item.attributes.speed, speedUnit, t);
          case 'deviceFuelIncrease':
            return formatVolume(item.attributes.volume, volumeUnit, t, 0);
          default:
            return '';
        }
      default:
        return item[key];
    }
  };

  const rowShimmer = (cols) => {
    const callback = () => (
      <VirtualizedTableShimmer columns={cols.length + 1} compact />
    );
    return callback;
  };
  const top5Headers = (key) => {
    const callback = () => (
      <TableRow className={classes.tableHeader}>
        <TableCell sx={{ p: 0, pl: 2 }}>{t('sharedDevice')}</TableCell>
        <TableCell sx={{ p: 0, pl: 2 }}>{t(key)}</TableCell>
        {top5Columns.map((key) => (<TableCell key={key} sx={{ px: 1 }}>{t(columnsMap.get(key))}</TableCell>))}
      </TableRow>
    );
    return callback;
  };
  const top5RowContent = (key, valueGetter) => {
    const callback = (_index, row) => {
      const { deviceid } = row;
      row[key] = row[valueGetter];
      return (
        <>
          <TableCell sx={{ px: 2, py: 0, minWidth: { xs: 100 } }}>{devices[deviceid].name}</TableCell>
          <TableCell sx={{ px: 2, py: 0, minWidth: { xs: 100 } }}>{formatValue(row, key)}</TableCell>
          {top5Columns.map((key) => (
            <TableCell key={key} sx={{ p: 0, px: 1, textAlign: 'start', minWidth: { xs: 80 } }}>
              {formatValue(row, key)}
            </TableCell>
          ))}
        </>
      );
    };
    return callback;
  };

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle']}>
      <div className={classes.container}>
        <div className={classes.header}>
          <CorporateReportFilter
            handleSubmit={handleSubmit}
            ignoreDevice
            includeGroups={['branch', 'project', 'construction']}
            deviceType="heavy-duty"
            isDaily
            showOnly
            handleFilter={handleCategoryFilter}
          >
            {/* <ColumnSelect columns={columns} setColumns={setColumns} columnsArray={columnsArray} /> */}
          </CorporateReportFilter>
        </div>
        <div className={classes.containerMain}>
          <Grid container sx={{ flex: 1, px: 2 }} spacing={2}>
            {/* LEFT COLUMN */}
            <Grid item container xs={12} md={5} direction="column" spacing={2} sx={{ height: 640 }}>
              {/* TABLA */}
              <Grid item sx={{ width: '100%' }} xs>
                <Box sx={{ height: 492 }}>
                  <CustomDataGrid
                    columns={engineHoursColumns}
                    rows={showItems}
                    checkboxSelection
                    selections={selectedItems}
                    setSelections={setSelectedItems}
                  />
                </Box>
              </Grid>
              {/* TOTALES */}
              <Grid item container columnSpacing={2} direction="row" xs="auto">
                <TotalCard title={t('reportOperativeEfficiency')} value={`${totalOperativeEfficiency()}%`} width={4} />
                <TotalCard title={`${t('reportEnergeticEfficiency')} `} value={`${totalEnergeticEfficiency('engineHours')} h/l`} width={4} />
                <TotalCard title={`${t('reportEnergeticEfficiency')} `} value={`${totalEnergeticEfficiency('pumpedMaterial')} m³/l`} width={4} />
              </Grid>
            </Grid>
            {/* RIGHT COLUMN */}
            <Grid item container xs={12} md={7} direction="column" spacing={2} sx={{ height: 640 }}>
              {/* CHARTS */}
              {renderCharts(category, t)}
            </Grid>
            {/* TOP 5 */}
            {/* <Grid item xs={12} container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{ bgcolor: palette.primary.main, color: palette.primary.contrastText, px: 2, my: 1, borderRadius: 4 }}
                >
                  {`Top 5 ${t('reportPumpedMaterial')}`}
                </Typography>
                <Box sx={{ height: '200px' }}>
                  <VirtualizedTable
                    columns={top5Headers('reportPumpedMaterial')}
                    rowContent={!loading && items.length > 0 ? top5RowContent('pumpedMaterial', 'productionValue') : rowShimmer([...Array(5)])}
                    rows={!loading && items.length > 0 ?
                      truncateItems(sortedItems(filterItems(chartItems, 'productionUnit', 'pumpedMaterial'), 'productionValue'), 5)
                      :
                      [...Array(5)]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{ bgcolor: palette.primary.main, color: palette.primary.contrastText, px: 2, my: 1, borderRadius: 4 }}
                >
                  {`Top 5 ${t('reportSpentFuel')}`}
                </Typography>
                <Box sx={{ height: '200px' }}>
                  <VirtualizedTable
                    columns={top5Headers('reportSpentFuel')}
                    rowContent={!loading && items.length > 0 ? top5RowContent('spentFuel', 'spentFuel') : rowShimmer([...Array(5)])}
                    rows={!loading && items.length > 0 ?
                      truncateItems(sortedItems([...chartItems], 'spentFuel'), 5)
                      :
                      [...Array(5)]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{ bgcolor: palette.primary.main, color: palette.primary.contrastText, px: 2, my: 1, borderRadius: 4 }}
                >
                  {`Top 5 ${t('reportEngineHours')}`}
                </Typography>
                <Box sx={{ height: '200px' }}>
                  <VirtualizedTable
                    columns={top5Headers('reportEngineHours')}
                    rowContent={!loading && items.length > 0 ? top5RowContent('engineHours', 'totalHours') : rowShimmer([...Array(5)])}
                    rows={!loading && items.length > 0 ?
                      truncateItems(sortedItems([...chartItems], 'totalHours'), 5)
                      :
                      [...Array(5)]}
                  />
                </Box>
              </Grid>
            </Grid> */}
          </Grid>
        </div>
      </div>
    </PageLayout>
  );
};

export default CorporatePerformancePage;
