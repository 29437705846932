import React from 'react';
import { Skeleton, TableCell } from '@mui/material';

const VirtualizedTableShimmer = ({ columns, startAction, endAction, compact }) => (
  [...Array(columns)].map((_, j) => {
    const action = (startAction && j === 0) || (endAction && j === columns - 1);
    return (
      <TableCell key={-j} padding={action ? 'none' : 'normal'} sx={{ py: compact ? 0 : 'inherit' }}>
        {!action && <Skeleton />}
      </TableCell>
    );
  })
);

export default VirtualizedTableShimmer;
